<form novalidate  >
  <nsc-system-message></nsc-system-message>

  <nsc-card *ngIf="content.welcomeMessage">
    <nsc-card-title>School Notifications</nsc-card-title>
    <nsc-card-content>
        <div  [ngClass]="{'ql-editor':content.isNewEditor}" class="nsc-welcome-message" [innerHTML]="content.welcomeMessage | safeHtml:'html'">
      </div>
    </nsc-card-content>
  </nsc-card>

  <nsc-spacer size="md" *ngIf="content.welcomeMessage"></nsc-spacer>

  <nsc-card>
    <nsc-card-title>Clearinghouse Notifications</nsc-card-title>
    <nsc-card-content>
      <ul class="nsc-clearinghouse-notif">
        <li><b>Electronic Transcripts -</b> Must be retrieved <b>using a desktop or laptop computer</b> due to security features applied to the document. Mobile device and tablet access to the Download Center is blocked.</li>
         <li><b>Payment -</b> If a cost is involved, payment will be accepted with any major credit card or debit card. Your credit or debit card is not charged until your school sends your transcript(s). However, if you use a debit card, your bank may put a hold on your funds when we pre-authorize your payment. If you have any questions about a pre-authorization, please contact your bank.</li>
      </ul> 
        <div>
          <button mat-raised-button id="schoolselect_addprocess" class="nsc-btn__right" color="primary"
          type="button" (click)="redirectToProcessFlowLink()">Learn How the Process Works
          <mat-icon aria-hidden="true">chevron_right</mat-icon></button>
        </div>
    </nsc-card-content>
  </nsc-card>

  <nsc-spacer size="md"></nsc-spacer>
  
  <nsc-card>
    <nsc-card-title>Terms of Use</nsc-card-title>
    <nsc-card-content>
      <ul class="nsc-clearinghouse-notif">
        <li><b>Permitted Use of Service -</b> The public ordering site is designed and intended to be used exclusively by students and alumni ordering their own transcripts. Any use of the service outside of this specific purpose is strictly prohibited and constitutes a violation of our <a href="https://www.studentclearinghouse.org/terms-of-use/" target="_blank">Terms of Use</a> which may result in potential legal action.</li>
         <li><b>Your Information -</b> The personal identifiers you provide as part of your order, which may include your social security number and/or student ID, will be transmitted to your institution to locate your education records and create your transcript. By providing such information, you authorize the use and disclosure of the provided personal identifiers for this purpose.</li>
      </ul> 
    </nsc-card-content>
  </nsc-card>

  <nsc-spacer size="md"></nsc-spacer>

  <div class="nsc-form-actions">
    <button
      mat-raised-button
      id="ordertranscript_button"
      class="nsc-form-actions__button nsc-btn__right button-big"
      color="primary"
      type="button"
      (click)="continue()"> Order Transcript(s) <mat-icon aria-hidden="true">chevron_right</mat-icon></button>
  </div>
  <nsc-spacer size="md"></nsc-spacer>
  <div class="col-12 d-flex justify-content-center">
      <a id="view_transcript_status" aria-label="View Transcript Order Status" [routerLink]="[]" (click)="openConfirmationDialog()">View Transcript Order Status</a>
  </div>
</form>
