import * as moment from 'moment';
import { Component, OnDestroy, OnInit, Renderer2, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { SelectOption } from '../../interfaces/select-option.interface';
import {
  cloneDeep as _cloneDeep,
  forEach as _forEach,
  filter as _filter,
  get as _get,
  merge as _merge,
  sortBy as _sortBy,
  intersectionwith as _intersectionwith
} from 'lodash';
import { Router } from '@angular/router';
import { BehaviorSubject, Subject, Observable, merge } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { BlockingIndicatorService } from '../../services/blocking-indicator/blocking-indicator.service';
import { CommonValues } from '../../classes/common-values';
import { DataService } from '../../services/data/data.service';
import { ManageFields } from '../../classes/manage-fields';
import { SecurityService } from '../../services/security/security.service';
import { StudentService } from '../../services/student/student.service';
import { TimeoutService } from '../../services/timeout/timeout.service';
import { ValidateDateCompare } from '../../validators/date-compare.validator';
import { ValidateDateOfBirth } from '../../validators/date-of-birth.validator';
import { ValidateRequireEither } from '../../validators/require-either.validator';
import { ValidateMatch } from '../../validators/match.validator';
import { ValidateStudentIdPrimary } from '../../validators/student-primary-validator';
import { StatesService } from '../../services/states/states.service';
import { States } from '../../interfaces/states.interface';
import { SelectOptgroup } from '../../interfaces/select-optgroup.interface';
import { SCCampus } from '../../interfaces/api-response-school-profile.interface';




@Component({
  selector: 'nsc-requestor-identification',
  templateUrl: './requestor-identification.component.html',
  styleUrls: ['./requestor-identification.component.scss']
})
export class RequestorIdentificationComponent implements OnDestroy, OnInit {

  // using setter method beacuse the element doesn't exist when the html is loaded
  // this will catch whenever the element is present in the html
  @ViewChild('updateInfo', { read: ElementRef }) set controlElRef(el: ElementRef) {
    if ((el && !this.values.verifyStudentFailed) || (el && !this.show.verifyStudentWithHolds)) {
      const isRadioBtnChecked = el.nativeElement.querySelectorAll('.mat-radio-checked');
      if (isRadioBtnChecked.length === 0) {
        let defaultField ='studentnotfound_yes-input';
        if(this.show.verifyStudentWithHolds && !this.formGroup.controls['proceedWithHolds'].disabled){
          defaultField= 'proceed_withholds_yes-input';
        }
         (document.getElementById(defaultField) as HTMLInputElement).focus();
      }
    }
  }


  data = this.dataService.get();
  askSSN = this.data?.schoolProfile?.askSsn;
  askStudentId = this.data?.schoolProfile?.askStudentId;
  nodeLabel = (this.askSSN === "N" && this.askStudentId ==="N") ? this.commonValues.api.attendanceInformation:this.commonValues.api.studentIdentificationInformation;
  postAuthorizationData: any;
  hasOneState = false;
  ssnSidNote = "One of the following is required";
  note: string;
  displayValues = [
    this.commonValues.api.both,
    this.commonValues.api.optional,
    this.commonValues.api.required
  ];
  formGroup: FormGroup;
  requireValues = [this.commonValues.api.both, this.commonValues.api.required];
  unsubscribe$ = new Subject();

  content = {
    enrolledBeforeQuestion: this.data.schoolProfile.electronicDeliveryEligibilityQuestion,
    labelStudentId: this.data.schoolProfile.studentIdLabel || 'Student ID',
    holds: [],
    pathQuestion: _get(this.data.schoolProfile, 'feePathDesc'),
    pathHelp: this.dataService.getNote('39'),
    labelState: this.commonValues.address.labelState,
    labelStateUS: this.commonValues.address.labelStateUS,
    scCampus: this.data.schoolProfile.scCampus,
    schoolName: this.data.schoolProfile.schlName
  };

  masks = {
    date:"00/00/0000",
    year: "0000",
  };

  require = {
    enrolledBeforeQuestion:
      this.content.enrolledBeforeQuestion !== undefined &&
      this.content.enrolledBeforeQuestion !== null,
    one:
      this.data.schoolProfile.askSsn === this.commonValues.api.both &&
      this.data.schoolProfile.askStudentId === this.commonValues.api.both,
    ssnOriginal: this.requireValues.indexOf(this.data.schoolProfile.askSsn) > -1,
    ssnPrimary: null,
    ssnConfirm: null,
    studentIdOriginal: this.requireValues.indexOf(this.data.schoolProfile.askStudentId) > -1,
    studentIdPrimary: null,
    studentIdConfirm: null
  };

  show = {
    deliveryUnavailable: false,
    enrolledBeforeQuestion:
      this.data.form.requestor.personal.enrolledCurrently === this.commonValues.api.no,
    pathQuestion:
      this.data.schoolProfile.feePathTypePub === this.commonValues.api.both && this.data.schoolProfile.feePathDesc ,
    
    ssn: this.displayValues.indexOf(this.data.schoolProfile.askSsn) > -1,
    studentIdPrimary: this.displayValues.indexOf(this.data.schoolProfile.askStudentId) > -1,
    studentIdConfirm: this.displayValues.indexOf(this.data.schoolProfile.askConfirmStId) > -1,
    // studentIdRow is used to hide / display the studentID when the value is prepoulated.
    studentIdRow: true,
    // ssnRow is used to hide / display the ssn when the value is prepopulated.
    ssnRow: true,
    // pathQuestionSSS is used to hide / display the FreePath question when the free path type is prepopulated.
    pathQuestionSSS: false,
    verifyStudentFail: false,
    verifyStudentWithHolds: false,
    campusValues: false,
    zeroFeePrgmId: this.data.schoolProfile.zeroFeePrgmId,
    allowStudentName: this.data.schoolProfile.allowStudentName 
                      && this.data.schoolProfile.allowStudentName !== this.commonValues.studentName.none.code
  };

  values = {
    verifiedSamlStudent: false,
    managedControls: [],
    states$: new BehaviorSubject(<SelectOptgroup[]>[]),
    statesUS: <SelectOptgroup[]>[], // populated by the states API;
    campusValues: <SelectOption[]>[],
    completeCampusValues: <SelectOption[]>[],

    // validators initialized here;
    // updated on form init;
    // used again if only ssn or student id are required (this.require.one);
    ssnValidators: [Validators.pattern(/^\d{3}-?\d{2}-?\d{4}$/)],
    studentIdValidators: [],
    studentIdMinLength: (this.data.schoolProfile.stIdMinLimit ? this.data.schoolProfile.stIdMinLimit : (this.data.schoolProfile.stIdMaxLimit || 2)),
    studentIdMaxLength: this.data.schoolProfile.stIdMaxLimit || 20,

    // track if the user has accessed the verify student API;
    verifyStudentFailed: false,
    no: this.commonValues.api.no,
    yes: this.commonValues.api.yes,
    livedNameValidator: [],
    studentNameLabel: (this.data.schoolProfile.allowStudentName === this.commonValues.studentName.livedName.code)
                        ? this.commonValues.studentName.livedName.name 
                        : this.commonValues.studentName.preferredName.name


  };

  livedNameToolTipMsg = "The " +this.values.studentNameLabel+" is a self-chosen or preferred personal and/or professional name used instead of a legal name. Providing a " + this.values.studentNameLabel +" is informational for your school and does not mean it will appear on your transcript."
    


  constructor(
    private blockingIndicatorService: BlockingIndicatorService,
    private commonValues: CommonValues,
    private dataService: DataService,
    private formBuilder: FormBuilder,
    private manageFields: ManageFields,
    private router: Router,
    private securityService: SecurityService,
    private studentService: StudentService,
    private timeoutService: TimeoutService,
    private statesService: StatesService,
    private renderer: Renderer2,
  ) { }

  ngOnInit() {
    this.require.ssnConfirm = this.require.ssnOriginal;
    this.require.ssnPrimary = this.require.ssnOriginal;
    this.require.studentIdConfirm = this.require.studentIdOriginal;
    this.require.studentIdPrimary = this.require.studentIdOriginal;
    if (this.require.ssnPrimary && this.require.studentIdPrimary && this.require.one) { this.note = this.ssnSidNote }
    else { this.note = "" }


    // initPostAuthorizationData is required to read the Post Authorization Value
    this.initPostAuthorizationData();
    this.initForm();
    this.initMyHubUserAuthValues();
    this.initStateValues();
    this.initCampusValues();
    this.initConfirmListeners();
    this.initNameChangedListener();
    if(this.show.allowStudentName){
     this.initAllowStudentNameListener();
    }
    this.initEnrolledCurrentlyListener();
    this.initEnrolledBeforeListner();
    this.initPathListner();
    this.initProceedWithStudentNotFoundListener();
    // setcustomValidators is required for Student Self Service SSN and Student ID prepoulation logic.
    if (this.postAuthorizationData &&
      (this.postAuthorizationData.appType === this.commonValues.applicationType.studentSelfService || this.postAuthorizationData.appType === this.commonValues.applicationType.saml)) {
      this.setcustomValidators();
    }
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();

  }

  initStateValues(): void {
    this.statesService.data$.pipe(takeUntil(this.unsubscribe$)).subscribe((json: States) => {
      this.values.statesUS = _cloneDeep(json.us);
    });

    for (let i = 0; i < this.values.statesUS.length; i++) {
      this.compare(this.values.statesUS[i].options, this.data.schoolProfile.scCampus, i);
    }

    // to remove labels with empty options
    let tempArray = [];
    _forEach(this.values.statesUS, function (val) {
      if (val.options.length !== 0) {
        tempArray.push(val);
      }
    });

    this.values.statesUS = tempArray;

    if (tempArray.length > 0) {
      this.show.campusValues = true;
      // alphabetize the array;
      this.values.statesUS = _sortBy(this.values.statesUS, 'name');
      this.values.states$.next(this.values.statesUS);

    }
    // if there is only one state available then default the state valuein the UI.
    if (tempArray && tempArray.length === 1) {
      if (tempArray[0].options.length === 1) {
        this.formGroup.controls.state.setValue(tempArray[0].options[0].value);
      }
    } else if (tempArray.length === 0) {
      this.removeValidators('state');
    }

  }

  // compare arr of all states with array of campuse's states
  compare(arr1, arr2, i): void {
    let finalArray = [];
    arr1.forEach((e1) => arr2.forEach((e2) => {
      if (e1.value === e2.state) {
        let index = finalArray.indexOf(e1);
        // push only if the state does'nt exits already
        if (index === -1) {
          finalArray.push(e1);
        }
      }
    }
    ));
    this.values.statesUS[i].options = finalArray;
  }

  initCampusValues(): void {
    // map the data from toschool profile to campusValues
    let scCampusList = _cloneDeep(this.data.schoolProfile.scCampus);
    // this is complete campuses list which won't change
    this.values.completeCampusValues = scCampusList.map(
      (option: SCCampus) => {
        return {
          name: option.campusName,
          value: option.toScCampusId,
          category: option.state
        };
      }
    );
    // if there is only one state available then set the campuses for that state on page load
    if (this.values.completeCampusValues.length !== 0) {
      this.show.campusValues = true;
      if (this.values.statesUS.length === 1) {
        if (this.values.statesUS[0].options.length === 1) {
          this.filterCampusesForState(this.values.statesUS[0].options[0].value);
        }
      }
    } else {
      this.removeValidators('campus');
    }
  }
  initForm(): void {
    const requestor = this.data.form.requestor.personal;
    const hasNameChanged = requestor.nameChanged === this.commonValues.api.yes;
    const allowStudentNameToggle = requestor.allowStudentName === this.commonValues.api.yes;
    const formValidators = [ValidateDateCompare('enrolledYearFrom', 'enrolledYearTo')];

    // may not need to validate that student ids match;
    // because we may not be showing both fields;
    // but if we show them, make sure their values match;
    if (this.show.studentIdRow) {
      formValidators.push(ValidateMatch('studentIdPrimary', 'studentIdConfirm'));
      this.values.studentIdValidators.push(Validators.minLength(this.values.studentIdMinLength));
      this.values.studentIdValidators.push(Validators.maxLength(this.data.schoolProfile.stIdMaxLimit));
      this.values.studentIdValidators.push(ValidateStudentIdPrimary('studentIdPrimary',this.values.studentIdMaxLength, this.values.studentIdMinLength));
    }

    // if we show one SSN field, we show the other;
    // if we show them, make sure their values match;
    if (this.show.ssnRow) {
      formValidators.push(ValidateMatch('ssnPrimary', 'ssnConfirm'));
    }

    // if only one identifier (SSN or Student ID) is required;
    // set a validator making sure at least one value is entered;
    // dont need to check the confirm fields;
    // because those validations will be triggered when the user enters a value in the primary field;
    if (this.require.one && this.show.ssnRow && this.show.studentIdRow) {
      formValidators.push(ValidateRequireEither('studentIdPrimary', 'ssnPrimary'));
    }

    // set validators for the student id fields;
    // just because they are visible does not mean they are required;
    // but only do that when the requirements aren't `require one of the two`;
    else {
      if (this.require.studentIdPrimary) {
        this.values.studentIdValidators.push(Validators.required);
      }

      if (this.require.ssnPrimary) {
        this.values.ssnValidators.push(Validators.required);
      }
    }

    if(this.show.allowStudentName){
      this.values.livedNameValidator.push(Validators.required);
    }

    // set the default values based on their visiblity;
    // we need to have the fields disabled if they aren't shown so they won't impede form submission;
    const studentIdPrimaryValue = this.show.studentIdPrimary
      ? requestor.studentIdPrimary
      : this.commonValues.nullDisabled;

    const studentIdConfirmValue = this.show.studentIdConfirm
      ? requestor.studentIdPrimary
      : this.commonValues.nullDisabled;

    const ssnPrimaryValue = this.show.ssn ? requestor.ssnPrimary : this.commonValues.nullDisabled;

    const ssnConfirmValue = this.show.ssn ? requestor.ssnPrimary : this.commonValues.nullDisabled;

    const enrolledBeforeValue = this.require.enrolledBeforeQuestion
      ? requestor.enrolledBefore
      : this.commonValues.nullDisabled;

    const nameChangedFirstValue = hasNameChanged
      ? requestor.nameChangedFirst
      : this.commonValues.nullDisabled;

    const nameChangedLastValue = hasNameChanged
      ? requestor.nameChangedLast
      : this.commonValues.nullDisabled;

    const livedNameFirstValue = allowStudentNameToggle
      ? requestor.livedNameFirst
      : this.commonValues.nullDisabled;

    const livedNameLastValue = allowStudentNameToggle
      ? requestor.livedNameLast
      : this.commonValues.nullDisabled;

    // initialize the form now that all configuration is set;
    this.formGroup = this.formBuilder.group(
      {
        nameFirst: new FormControl(requestor.nameFirst, [Validators.required]),
        nameMiddle: new FormControl(requestor.nameMiddle),
        nameLast: new FormControl(requestor.nameLast, [Validators.required]),
        dob: new FormControl(requestor.dob, [Validators.required, ValidateDateOfBirth]),
        nameChanged: new FormControl(requestor.nameChanged, [Validators.required]),
        nameChangedFirst: new FormControl(nameChangedFirstValue, [Validators.required]),
        nameChangedMiddle: new FormControl(
          hasNameChanged ? requestor.nameChangedMiddle : this.commonValues.nullDisabled
        ),
        nameChangedLast: new FormControl(nameChangedLastValue, [Validators.required]),
        allowStudentName: new FormControl(requestor.allowStudentName, this.values.livedNameValidator),
        livedNameFirst: new FormControl(livedNameFirstValue, [Validators.required]),
        livedNameMiddle: new FormControl(
          allowStudentNameToggle ? requestor.livedNameMiddle : this.commonValues.nullDisabled
          ),
        livedNameLast: new FormControl(livedNameLastValue, [Validators.required]),
        studentIdPrimary: new FormControl(studentIdPrimaryValue, this.values.studentIdValidators),
        studentIdConfirm: new FormControl(studentIdConfirmValue, this.values.studentIdValidators),
        ssnPrimary: new FormControl(ssnPrimaryValue, this.values.ssnValidators),
        ssnConfirm: new FormControl(ssnConfirmValue, this.values.ssnValidators),
        path: new FormControl(
          this.show.pathQuestion ? requestor.path : this.commonValues.nullDisabled,
          [Validators.required]
        ),
        enrolledCurrently: new FormControl(requestor.enrolledCurrently, [Validators.required]),
        zeroFeePrgmCurrEnroll: new FormControl(requestor.zeroFeePrgmCurrEnroll, [Validators.required]),
        state: new FormControl(requestor.state, [Validators.required]),
        campus: new FormControl(requestor.campus, [Validators.required]),
        enrolledBefore: new FormControl(enrolledBeforeValue, [Validators.required]),
        enrolledYearFrom: new FormControl(
          requestor.enrolledYearFrom || this.commonValues.nullDisabled,
          [
            Validators.required,
            Validators.minLength(4),
            Validators.min(1940),
            Validators.max(moment().year() + 1)
          ]
        ),
        enrolledYearTo: new FormControl(
          requestor.enrolledYearTo || this.commonValues.nullDisabled,
          [Validators.required, Validators.minLength(4), Validators.min(1940)]
        ),
        proceedWithHolds: new FormControl(this.commonValues.nullDisabled, [Validators.required]),
        proceedWithStudentNotFound: new FormControl(this.commonValues.nullDisabled, [
          Validators.required
        ]),
        disableUntilSomethingTouched: new FormControl(this.commonValues.nullDisabled, [
          Validators.required
        ])
      },
      {
        validator: formValidators
      }
    );
    

    // if any of the below values change then the student has modified his personal info
    // so continue can be enabled.
    merge(
      this.formGroup.get('nameLast').valueChanges,
      this.formGroup.get('nameFirst').valueChanges,
      this.formGroup.get('nameMiddle').valueChanges,
      this.formGroup.get('dob').valueChanges,
      this.formGroup.get('nameChanged').valueChanges,
      this.formGroup.get('nameChangedFirst').valueChanges,
      this.formGroup.get('nameChangedMiddle').valueChanges,
      this.formGroup.get('nameChangedLast').valueChanges,
      this.formGroup.get('allowStudentName').valueChanges,
      this.formGroup.get('livedNameFirst').valueChanges,
      this.formGroup.get('livedNameLast').valueChanges,
      this.formGroup.get('livedNameMiddle').valueChanges,
      this.formGroup.get('ssnPrimary').valueChanges,
      this.formGroup.get('studentIdPrimary').valueChanges
    ).subscribe(data => {
      this.formGroup.controls.disableUntilSomethingTouched.disable();
      this.formGroup.controls.proceedWithStudentNotFound.disable();
    });
  }

  initConfirmListeners(): void {
    // record original validators because we will be swapping them;
    const originalValidator = {
      studentId: _cloneDeep(this.values.studentIdValidators),
      ssn: _cloneDeep(this.values.ssnValidators)
    };

    // create a new set of validators that are required;
    const requiredValidator = {
      studentId: _cloneDeep(this.values.studentIdValidators),
      ssn: _cloneDeep(this.values.ssnValidators)
    };
    requiredValidator.studentId.push(Validators.required);
    requiredValidator.ssn.push(Validators.required);

    // update the form and control status so the form will properly report valid or not;
    const updateStatus = (value: string, control: string): void => {
      const formControl = this.formGroup.controls[control];

      // without checking the status, the loop onChange loop wouldn't end;
      // we want the reset, which changes the status;
      // because we want empty fields do not be displayed as an error, if this field is empty;
      if (!formControl.value && formControl.status === 'INVALID') {
        this.manageFields.reset([formControl]);
      }

      // trigger an update to prevent the form from being marked as valid when it isn't;
      // otherwise the field may be required and empty, but the form registers as valid;
      if (value && !formControl.value) {
        formControl.updateValueAndValidity();
      }
    };

    // set listeners on the controls to manage the related control's validation;
    // if a user enters in a confirm value, the primary value is now required;
    // if a user enters a primary value, the confirm value is now required;
    // if a user clears out a value, the related field is set back to the original validation state;

    this.formGroup.controls.studentIdPrimary.valueChanges
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((value: string) => {
        if(value && value.indexOf("-") !== -1) {
          this.formGroup.controls.studentIdPrimary.setValue(value.replace('-',''));
        }
        this.require.studentIdConfirm = value ? true : this.require.studentIdOriginal;
        this.formGroup.controls.studentIdConfirm.setValidators(
          value ? requiredValidator.studentId : originalValidator.studentId
        );

        updateStatus(value, 'studentIdConfirm');
      });

    this.formGroup.controls.studentIdConfirm.valueChanges
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((value: string) => {
        if(value && value.indexOf("-") !== -1) {
          this.formGroup.controls.studentIdConfirm.setValue(value.replace('-',''));
        }
        this.require.studentIdPrimary = value ? true : this.require.studentIdOriginal;
        this.formGroup.controls.studentIdPrimary.setValidators(
          value ? requiredValidator.studentId : originalValidator.studentId
        );

        updateStatus(value, 'studentIdPrimary');
      });

    this.formGroup.controls.ssnPrimary.valueChanges
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((value: string) => {
        this.require.ssnConfirm = value ? true : this.require.ssnOriginal;
        this.formGroup.controls.ssnConfirm.setValidators(
          value ? requiredValidator.ssn : originalValidator.ssn
        );
        updateStatus(value, 'ssnConfirm');
      });

    this.formGroup.controls.ssnConfirm.valueChanges
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((value: string) => {
        this.require.ssnPrimary = value ? true : this.require.ssnOriginal;
        this.formGroup.controls.ssnPrimary.setValidators(
          value ? requiredValidator.ssn : originalValidator.ssn
        );

        updateStatus(value, 'ssnPrimary');
      });

    this.formGroup.controls.state.valueChanges
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((value: string) => {
        this.filterCampusesForState(value);
        this.manageFields.reset([this.formGroup.controls.campus]);

      });

  }



  // function to filter sc campuses by state value
  filterCampusesForState(value) {
    this.values.campusValues = _filter(this.values.completeCampusValues, {
      category: value
    });
    // alphabetize the array;
    this.values.campusValues = _sortBy(this.values.campusValues, 'name');
  }


  initNameChangedListener(): void {
    // watch the `nameChanged` field because when toggled we want to hide/show form fields, and adjust required fields;
    this.formGroup.controls.nameChanged.valueChanges
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((value: string) => {
        const controls = this.formGroup.controls;

        if (value) {
          switch (value) {
            case this.commonValues.api.yes:
              // enable validation on the now visible form fields;
              this.manageFields.enable([
                controls.nameChangedFirst,
                controls.nameChangedMiddle,
                controls.nameChangedLast
              ]);
              if (this.postAuthorizationData) {
                if (this.postAuthorizationData.formerFirstName) {
                  this.formGroup.controls['nameChangedFirst'].setValue(this.postAuthorizationData.formerFirstName.toString().slice(0, 35));
                }
                if (this.postAuthorizationData.formerLastName) {
                  this.formGroup.controls['nameChangedLast'].setValue(this.postAuthorizationData.formerLastName.toString().slice(0, 35));
                }
              }
              break;

            case this.commonValues.api.no:
              // remove the hidden fields from the validation model;
              this.manageFields.disable([
                controls.nameChangedFirst,
                controls.nameChangedMiddle,
                controls.nameChangedLast
              ]);
              break;
          }
        }
      });
  }

  initAllowStudentNameListener(): void {
    // watch the `allowStudentName` field because when toggled we want to hide/show form fields, and adjust required fields;
    this.formGroup.controls.allowStudentName.valueChanges
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((value: string) => {
        const controls = this.formGroup.controls;

        if (value) {
          switch (value) {
            case this.commonValues.api.yes:
              // enable validation on the now visible form fields;
              this.manageFields.enable([
                controls.livedNameFirst,
                controls.livedNameMiddle,
                controls.livedNameLast
              ]);
              break;
            case this.commonValues.api.no:
              // remove the hidden fields from the validation model;
              this.manageFields.disable([
                controls.livedNameFirst,
                controls.livedNameMiddle,
                controls.livedNameLast
              ]);
              break;
          }
        }
      });
  }

  initEnrolledCurrentlyListener(): void {
    // watch the `enrolledCurrently` field because when toggled we want to hide/show form fields;
    this.formGroup.controls.enrolledCurrently.valueChanges
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((value: string) => {
        const controls = this.formGroup.controls;

        if (value) {
          // control the visibility of the `enrolledBefore` question;
          // preselect NO if the question is required, but we aren't going to show the question;

          if (value === this.commonValues.api.yes && this.require.enrolledBeforeQuestion) {
            this.formGroup.patchValue({
              enrolledBefore: this.commonValues.api.no
            });
          }

          // if `enrolledBefore` is required, determine if it should be shown;
          if (this.require.enrolledBeforeQuestion) {
            this.show.enrolledBeforeQuestion = value === this.commonValues.api.no;
          }

          // if the user is currently enrolled the enrolledBefore question has been hidden, so we should hide the year fields;
          if (value === this.commonValues.api.yes) {
            this.manageFields.disable([controls.enrolledYearFrom, controls.enrolledYearTo, controls.zeroFeePrgmCurrEnroll]);
          } else {
            this.manageFields.enable([controls.enrolledYearFrom, controls.enrolledYearTo, controls.zeroFeePrgmCurrEnroll]);
          }

          if (!this.show.zeroFeePrgmId) {
            controls.zeroFeePrgmCurrEnroll.disable();
          }
        }
      });
  }

  initEnrolledBeforeListner(): void {
    this.formGroup.controls.enrolledBefore.valueChanges
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((value: string) => {
        if (value) {
          // determine if the delivery unavailable message should display, since it is dependant on this fields value;
          this.setShowDeliveryUnavailable();
        }
      });
  }

  initPathListner(): void {
    this.formGroup.controls.path.valueChanges
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((value: string) => {
        if (value) {
          // determine if the delivery unavailable message should display, since it is dependant on this fields value;
          this.setShowDeliveryUnavailable();
        }
      });
  }

  initProceedWithStudentNotFoundListener(): void {
    const proceedWithStudentNotFound = this.formGroup.controls.proceedWithStudentNotFound;
    const disableUntilSomethingTouched = this.formGroup.controls.disableUntilSomethingTouched;
    // watch the `proceedWithStudentNotFound` field because when toggled we want to enable the form controls;
    proceedWithStudentNotFound.valueChanges
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((value: string) => {
        const isYes = value && value === this.commonValues.api.yes;
        const isNo = value && value === this.commonValues.api.no;
        // if the value is `no`
        // then we don't care, the user isn't going to change anything;
        // if the value is `yes` and this is the first time through (the field is enabled);
        // then re-enable all the fields that were disabled, and disable this control so the user can't select `no`;
        // on YES continue need to be disabled so handling this with a new form control disableUntilSomethingTouched
        // this will be disabled and hence form will be valid when student changes something in his personal info fields
        if (isYes && proceedWithStudentNotFound.enabled) {
          this.manageControls(true);
          this.values.verifyStudentFailed = true;
          disableUntilSomethingTouched.enable();
          if (this.values.verifiedSamlStudent){
            const controls = this.formGroup.controls;
            this.manageFields.enable([
              controls.nameLast,
              controls.nameFirst,
              controls.nameMiddle,
              controls.dob
            ]);
          }
          window.setTimeout(() => {
            this.renderer.selectRootElement('#firstNameField').focus();
          }, 0);
        } else if (isNo) {
          this.manageControls(false);
          this.values.verifyStudentFailed = true;
          disableUntilSomethingTouched.disable();
        }
      });
  }

  // If user is coming through MyHUb if available prepopulate the values
  initMyHubUserAuthValues(): void {
    if (this.postAuthorizationData && this.postAuthorizationData.appType !== this.commonValues.applicationType.studentSelfService) {
      const prepopulateValues = this.postAuthorizationData.appType === this.commonValues.applicationType.saml ? this.values.verifiedSamlStudent : true;
      if (this.postAuthorizationData.lastName && prepopulateValues) {
        this.formGroup.controls['nameLast'].setValue(this.postAuthorizationData.lastName.toString().slice(0, 35));
      }
      if (this.postAuthorizationData.firstName && prepopulateValues) {
        this.formGroup.controls['nameFirst'].setValue(this.postAuthorizationData.firstName.toString().slice(0, 35));
      }
      if (this.postAuthorizationData.middleName && prepopulateValues) {
        this.formGroup.controls['nameMiddle'].setValue(this.postAuthorizationData.middleName.toString().slice(0, 35));
      }
      if (this.postAuthorizationData.dateOfBirth && prepopulateValues) {
        this.formGroup.controls['dob'].setValue(this.postAuthorizationData.dateOfBirth);
      }
      if ( this.values.verifiedSamlStudent){
        const controls = this.formGroup.controls;
        controls.nameLast.disable();
        controls.nameFirst.disable();
        controls.nameMiddle.disable();
        if(this.postAuthorizationData.dateOfBirth){
            controls.dob.disable();
        }
      }
    }
  }

  setShowDeliveryUnavailable(): void {
    const enrolledBeforeYes =
      this.formGroup.controls.enrolledBefore.value === this.commonValues.api.yes;
    const deliveryMethodCountIsZero = this.getNonElectronicDeliveryMethodCount() === 0;

    // show/hide the warning message about no delivery methods being available;
    // electronic options aren't available for people who answer yes to this question;
    // if there are no delivery methods available, there's no reason to continue with the order;
    this.show.deliveryUnavailable = enrolledBeforeYes && deliveryMethodCountIsZero;
  }

  // determine if there are any available delivery methods once the electronic options are filtered out;
  getNonElectronicDeliveryMethodCount(): number {
    const payDeliveryMethods = this.data.schoolProfile.payOptions.deliveryMethods;
    const freeDeliveryMethods = this.data.schoolProfile.freeOptions.deliveryMethods;

    let deliveryMethods;

    switch (this.data.schoolProfile.feePathTypePub) {
      case this.commonValues.api.both:
        // if the user is suppose to answer the path quesiton, but hasn't, we can't determine the deliveryMethods;
        if (this.show.pathQuestion && this.formGroup.controls.path.value === null) {
          return null;
        } else {
          deliveryMethods =
            this.formGroup.controls.path.value === this.commonValues.api.free
              ? freeDeliveryMethods
              : payDeliveryMethods;
        }
        break;

      case this.commonValues.api.free:
        deliveryMethods = freeDeliveryMethods;
        break;

      case this.commonValues.api.pay:
        deliveryMethods = payDeliveryMethods;
        break;
    }

    const validDeliveryMethodValues = [this.commonValues.api.electronic, this.commonValues.api.etx];
    const filteredDeliveryMethods = _filter(
      deliveryMethods,
      method => validDeliveryMethodValues.indexOf(method.deliveryMethodType) === -1
    );

    return  filteredDeliveryMethods ? filteredDeliveryMethods.length : filteredDeliveryMethods;
  }

  isContinueDisabled(): boolean {
    // the continue button is enabled (return false) the following conditions are all true;
    // the form is valid;
    const formValid = this.formGroup.valid;

    // a delivery method is available;
    const deliveryAvailable = !this.show.deliveryUnavailable;

    // and a verified user is allowed to proceed with holds;
    // if the student isn't verified, dont prevent the user from proceeding - an invalid form will do that;
    // if a student is verifed and still on this form, then the holds question must be answered as yes;
    const studentCanProceed =
      this.dataService.isSchoolEllucian() && this.dataService.isStudentVerified()
        ? this.formGroup.controls.proceedWithHolds.enabled
          ? this.formGroup.controls.proceedWithHolds.value === this.commonValues.api.yes
          : false
        : true;

    return !(formValid && deliveryAvailable && studentCanProceed);
  }

  manageControls(bool: boolean): void {
    // if no managedControls have been set, this is the first time running this function (fields are getting disabled);
    // we need to record what the original set was so if re-enabled we dont enable hidden (and required) fields;
    if (this.values.managedControls.length === 0) {
      const obj = this.formGroup.controls;

      Object.keys(obj).forEach(key => {
        if (obj[key].enabled) {
          this.values.managedControls.push(obj[key]);
        }
      });
    }

    // now that we know what fields to manage;
    // make all fields readonly and let the user read whatever error is displaying;
    // dont disable via the ManageFields class because we dont want to nullify the values;
    // adding emitEvent:false since valueChanges event is triggering even when formControl is disabled or enabled
    this.values.managedControls.forEach(control => (bool ? control.enable({ emitEvent: false }) : control.disable({ emitEvent: false })));
  }

  continueGo(): void {
    this.securityService.setDeactivateWarning(false);

    this.router.navigate(
      [this.commonValues.routes.requestorAddress],
      this.commonValues.routes.extras
    );
  }

  continueSave(): void {
    // for verified saml student below fields are disabled therefore we need to retrieve the values again in order to save it properly
    if ( this.values.verifiedSamlStudent){
      this.formGroup.value.nameFirst = this.formGroup.get('nameFirst').value;
      this.formGroup.value.nameLast = this.formGroup.get('nameLast').value;
      this.formGroup.value.nameMiddle = this.formGroup.get('nameMiddle').value;
      this.formGroup.value.dob = this.formGroup.get('dob').value;
      this.formGroup.value.studentIdPrimary = this.postAuthorizationData.collegeStudentId.toString();
      this.formGroup.value.studentIdConfirm = this.postAuthorizationData.collegeStudentId.toString();
    }
    this.dataService.save(this.formGroup.value, 'form.requestor.personal');
  }

  continueCheck(): void {
    this.blockingIndicatorService.open();
    this.studentService
      .get()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        // no error, just keep moving in the logic flow;
        // act is abstracted because it's used in both success, and error;
        json => {
          this.continueAct();
        },
        // there was an error, so treat it like a student wasn't found;
        // then act like there wasn't an error in the API call;
        error => {
          console.error(error);

          this.dataService.save({
            studentProfile: {}
          });
          this.continueGo();
        }
      );
  }

  continueAct(): void {
    window.setTimeout(() => {
      const holds = this.dataService.getStudentHolds();
      const proceedWithHolds =
        this.data.schoolProfile.proceedWithHoldAllowed === this.commonValues.api.yes;

      // if verified;
      if (this.dataService.isStudentVerified()) {
        // disable the student not found question
        this.show.verifyStudentFail = false;
        // and there are no holds/terms/programs to display, let the user through;
        if (holds.length === 0) {
          this.continueGo();
        } else {
          // trigger the display of the inline message and form field;
          this.content.holds = _sortBy(holds, ['codeDescription']);
          this.show.verifyStudentWithHolds = true;

          // if there are holds, and the school will allow the user to continue;
          if (proceedWithHolds) {
            // once enabled we dont have to disable the field;
            // the user either checks yes (and can proceed) or no (and they're done);
            this.manageFields.enable([this.formGroup.controls.proceedWithHolds]);
          }

          this.blockingIndicatorService.close();
        }
      }
      // if not verified;
      else {
        // if this is the second attempt, let them through;
        if (this.values.verifyStudentFailed) {
          this.continueGo();
        }
        // otherwise let the user change their data;
        else {
          this.blockingIndicatorService.close();

          // show the message to indicate the verification failed;
          this.show.verifyStudentFail = true;
          this.manageFields.enable([this.formGroup.controls.proceedWithStudentNotFound]);
        }
      }
    }, this.commonValues.loading.delay);
  }

  cancel(): void {
    this.securityService.cancelOrder();
  }

  // Disable validators if any of the field is hidden and the continue button needs to be enabled.
  removeValidators(field): void {
    const el = this.formGroup.get(field);
    if (el) {
      el.clearAsyncValidators();
      el.clearValidators();
      el.disable();
    }
    this.require[field] = false;
  }

  initPostAuthorizationData(): void {
    // Populates the postAuthorization Data from the Authorization Service.
    this.postAuthorizationData = this.dataService.postAuthorizationData;
    if (this.postAuthorizationData){
       this.postAuthorizationData.appType = this.postAuthorizationData.appType ? this.postAuthorizationData.appType.toUpperCase() : null;
       if (this.postAuthorizationData.appType === this.commonValues.applicationType.saml){
        this.values.verifiedSamlStudent = this.dataService.isSamlStudentVerified();
      }
    }
    // SSN Row is used to hide and display the SSN when the value is prepopulated.
    this.show.ssnRow = this.show.ssn &&
      (!this.postAuthorizationData ||
        !this.postAuthorizationData.ssn ||
        (!this.postAuthorizationData.ssn && this.postAuthorizationData.appType === this.commonValues.applicationType.studentSelfService) ||
        (!this.postAuthorizationData.ssn && this.postAuthorizationData.appType === this.commonValues.applicationType.saml));
    // Student ID Row is used to hide and display the Student ID when the value is prepopulated.
    this.show.studentIdRow = this.show.studentIdPrimary &&
      (!this.postAuthorizationData ||
        (!this.postAuthorizationData.collegeStudentId ||
          (!this.postAuthorizationData.collegeStudentId && this.postAuthorizationData.appType === this.commonValues.applicationType.studentSelfService) ||
          ( !this.postAuthorizationData.collegeStudentId  && this.postAuthorizationData.appType === this.commonValues.applicationType.saml)));
  }

  setcustomValidators(): void {
    this.show.pathQuestion = false;
    // Student ID and SSN Do not require both
    if (this.data.schoolProfile.askSsn === this.commonValues.api.optional && this.data.schoolProfile.askStudentId ===
      this.commonValues.api.optional && (this.postAuthorizationData &&
        (this.postAuthorizationData.appType === this.commonValues.applicationType.studentSelfService || this.values.verifiedSamlStudent))) {
      this.show.ssnRow = false;
      this.show.studentIdRow = false;
    }
    // Student Self Service - Show SSN but do not require.
    else if (this.data.schoolProfile.askSsn === this.commonValues.api.optional && this.data.schoolProfile.askStudentId ===
      this.commonValues.api.no && this.data.schoolProfile.askConfirmStId === this.commonValues.api.optional && (this.postAuthorizationData &&
       (this.postAuthorizationData.appType === this.commonValues.applicationType.studentSelfService || this.values.verifiedSamlStudent))) {
      this.show.ssnRow = false;
      this.show.studentIdRow = false;
    }
    else if (this.data.schoolProfile.askSsn === this.commonValues.api.optional && this.data.schoolProfile.askStudentId ===
      this.commonValues.api.no && this.data.schoolProfile.askConfirmStId === this.commonValues.api.no && (this.postAuthorizationData &&
       (this.postAuthorizationData.appType === this.commonValues.applicationType.studentSelfService || this.values.verifiedSamlStudent))) {
      this.show.ssnRow = false;
      this.show.studentIdRow = false;
    }

    // Student Self Service - Show Student ID but do not require

    else if (this.data.schoolProfile.askSsn === this.commonValues.api.no && this.data.schoolProfile.askStudentId ===
      this.commonValues.api.optional && this.data.schoolProfile.askConfirmStId === this.commonValues.api.optional && (this.postAuthorizationData &&
        (this.postAuthorizationData.appType === this.commonValues.applicationType.studentSelfService ||  this.values.verifiedSamlStudent))) {
      this.show.ssnRow = false;
      this.show.studentIdRow = false;
    }
    else if (this.data.schoolProfile.askSsn === this.commonValues.api.no && this.data.schoolProfile.askStudentId ===
      this.commonValues.api.optional && this.data.schoolProfile.askConfirmStId === this.commonValues.api.no && (this.postAuthorizationData &&
        (this.postAuthorizationData.appType === this.commonValues.applicationType.studentSelfService || this.values.verifiedSamlStudent))) {
      this.show.ssnRow = false;
      this.show.studentIdRow = false;
    }
    // Student Self Service - Show Student ID but do not require

    else if (this.data.schoolProfile.askSsn === this.commonValues.api.optional && this.data.schoolProfile.askStudentId ===
      this.commonValues.api.optional && this.data.schoolProfile.askConfirmStId === this.commonValues.api.optional && (this.postAuthorizationData &&
        (this.postAuthorizationData.appType === this.commonValues.applicationType.studentSelfService || this.values.verifiedSamlStudent))) {
      this.show.ssnRow = false;
      this.show.studentIdRow = false;
    }
    // Student Self Service - Show Student ID and SSN and Require SSN
    else if (this.data.schoolProfile.askSsn === this.commonValues.api.required && this.data.schoolProfile.askStudentId ===
      this.commonValues.api.optional && this.data.schoolProfile.askConfirmStId === this.commonValues.api.optional && (this.postAuthorizationData.ssn &&
        (this.postAuthorizationData.appType === this.commonValues.applicationType.studentSelfService || this.values.verifiedSamlStudent))) {
      this.show.ssnRow = false;
      this.show.studentIdRow = false;
    }
    else if (this.data.schoolProfile.askSsn === this.commonValues.api.required && this.data.schoolProfile.askStudentId ===
      this.commonValues.api.optional && this.data.schoolProfile.askConfirmStId === this.commonValues.api.no && (this.postAuthorizationData.ssn &&
        (this.postAuthorizationData.appType === this.commonValues.applicationType.studentSelfService || this.values.verifiedSamlStudent) )) {
      this.show.ssnRow = false;
      this.show.studentIdRow = false;
    }
    // Student Self Service - Show Student ID and SSN and Require Student ID
    else if (this.data.schoolProfile.askSsn === this.commonValues.api.optional && this.data.schoolProfile.askStudentId ===
      this.commonValues.api.required && this.data.schoolProfile.askConfirmStId === this.commonValues.api.optional && (this.postAuthorizationData.collegeStudentId &&
        (this.postAuthorizationData.appType === this.commonValues.applicationType.studentSelfService ||  this.values.verifiedSamlStudent))) {
      this.show.ssnRow = false;
      this.show.studentIdRow = false;
    }
    else if (this.data.schoolProfile.askSsn === this.commonValues.api.optional && this.data.schoolProfile.askStudentId ===
      this.commonValues.api.required && this.data.schoolProfile.askConfirmStId === this.commonValues.api.no && (this.postAuthorizationData.collegeStudentId &&
        (this.postAuthorizationData.appType === this.commonValues.applicationType.studentSelfService  || this.values.verifiedSamlStudent))) {
      this.show.ssnRow = false;
      this.show.studentIdRow = false;
    }
    // Student Self Service - Show SSN and Student ID Require One.
    else if
    (this.require.one && (!this.show.ssnRow || !this.show.studentIdRow)) {
      this.show.ssnRow = false;
      this.show.studentIdRow = false;
    }
    // if ssnRow is not set , remove the required validators on the page
    if (!this.show.ssnRow) {
      this.removeValidators('ssnOriginal');
      this.removeValidators('ssnPrimary');
      this.removeValidators('ssnConfirm');
    }
    // if studentIdRow is not set, remove the required validators on the page
    if (!this.show.studentIdRow) {
      this.removeValidators('studentIdOriginal');
      this.removeValidators('studentIdPrimary');
      this.removeValidators('studentIdConfirm');
    }
    // if the FeePath type from Student Self Service is F/P - Hide the Fee Path Question , if the value is B or null display it. Check to make
    // to see if the School Profile SSS FeePath option is set to 'Both'
   
    if(this.postAuthorizationData.feepathType && (this.postAuthorizationData.feepathType === this.commonValues.api.free 
      || this.postAuthorizationData.feepathType === this.commonValues.api.pay)){
        this.show.pathQuestion = false;
      }else if(this.data.schoolProfile.feePathTypeSss === this.commonValues.api.both){
        this.show.pathQuestion = true;
      }

    if(this.show.pathQuestion){
      this.manageFields.enable([this.formGroup.controls.path]);
    }else{
      this.removeValidators('path');
    }

  }

  continue(): void {
    const controls = this.formGroup.controls;
    if (this.show.zeroFeePrgmId && this.show.zeroFeePrgmId > 0 && controls.enrolledCurrently.value === this.commonValues.api.yes) {
      this.data.form.requestor.personal.zeroFeePrgmCurrEnroll = this.commonValues.api.yes;
      controls.zeroFeePrgmCurrEnroll.setValue(this.commonValues.api.yes);
    }

    // save what the user has entered so far before diabling form controls
    this.continueSave();
    this.manageControls(false);

    // if this is not an Ellucian enabled school, then let the user proceed;
    if (this.dataService.isSchoolEllucian() === false) {
      this.continueSave();
      this.continueGo();
    }
    // if Ellucian School;
    // need to determine if the student is verified with Ellucian or not;
    else {
      // if already verified, then the user had to deal with `proceedWithHolds`;
      if (this.dataService.isStudentVerified()) {
        this.continueGo();
      }
      // student check failed and user wants to continue forward
      else if (this.values.verifyStudentFailed && this.formGroup.controls.proceedWithStudentNotFound.value === this.commonValues.api.no) {
        this.continueSave();
        this.continueGo();
      }
      // if the student wasn't found, then the user had to deal with `proceedWithStudentNotFound`
      else if (this.dataService.isStudentNotFound()) {
        this.continueSave();
        this.continueCheck();
      }
      // if the student hasn't been checked yet;
      else {
        this.continueSave();
        this.continueCheck();
      }
    }
  }
}